import React from 'react';
import { Link } from '@remix-run/react';

import { cn } from '~/utils/cn';

const Logo = ({
  className,
  asLink = true,
  centered
}: {
  className?: string;
  asLink?: boolean;
  centered?: boolean;
}) => (
  <Link
    to={asLink ? '/' : '#'}
    className={cn(
      'absolute size-fit z-30 transition-all left-3 top-safe-offset-3 md:left-6 md:top-safe-offset-6',
      asLink ? 'cursor-pointer' : 'cursor-default',
      centered && 'max-md:left-1/2 max-md:-translate-x-1/2',
      className
    )}
  >
    <img src="/logo-beta.svg" alt="" className="block w-32 max-w-[unset]" />
  </Link>
);

export default Logo;
