import React, { createContext, useContext } from 'react';

import { useWebPush } from '~/hooks/useWebPush';

type WebPushContextType = ReturnType<typeof useWebPush>;

const WebPushContext = createContext<WebPushContextType | null>(null);

export function WebPushProvider({ children }: { children: React.ReactNode }) {
  const webPushState = useWebPush();

  return (
    <WebPushContext.Provider value={webPushState}>
      {children}
    </WebPushContext.Provider>
  );
}

export function useWebPushContext() {
  const context = useContext(WebPushContext);
  if (!context) {
    throw new Error('useWebPushContext must be used within a WebPushProvider');
  }
  return context;
}
