import React, { useEffect, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { LinksFunction, MetaFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useRouteError
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { AnimatePresence, motion, MotionConfig } from 'motion/react';

import pattern from '~/assets/images/pattern.png';
import Logo from '~/components/Logo';
import SoundToggle from '~/components/SoundToggle';
import { SoundProvider } from '~/contexts/SoundContext';
import { WebPushProvider } from '~/contexts/WebPushContext';
import { generateSplashScreens } from '~/utils/generateSplashScreens';

import './styles/base.css';

export const meta: MetaFunction = () => [
  { title: 'Respawwwn' },
  { name: 'description', content: 'Guess the game every day!' },
  { charSet: 'utf-8' },
  {
    name: 'viewport',
    content:
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover'
  },
  { name: 'theme-color', content: '#362977' },
  { name: 'mobile-web-app-capable', content: 'yes' },
  { name: 'apple-mobile-web-app-capable', content: 'yes' },
  {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'black-translucent'
  },
  { name: 'apple-touch-fullscreen', content: 'yes' },
  { name: 'apple-mobile-web-app-title', content: 'Respawwwn' },
  { property: 'og:image', content: '/og-image.jpg' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' }
];

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon-96x96.png',
    sizes: '96x96'
  },
  {
    rel: 'icon',
    type: 'image/svg+xml',
    href: '/favicon.svg'
  },
  {
    rel: 'shortcut icon',
    href: '/favicon.ico'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png'
  },
  {
    rel: 'manifest',
    href: '/site.webmanifest'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Teko:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nanum+Pen+Script&family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap'
  },
  ...generateSplashScreens()
];

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isDailyPath = location.pathname === '/daily';
  const [hasSeenAnimation, setHasSeenAnimation] = useState(false);
  const [isPwa, setIsPwa] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsPwa(true);
      setHasSeenAnimation(true);
      return undefined;
    }

    const timer = setTimeout(() => {
      setHasSeenAnimation(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <html
      lang="en"
      className="h-viewport w-full touch-manipulation select-none overflow-hidden bg-purple-800"
    >
      <head>
        <Meta />
        <Links />
      </head>
      <body className="relative flex size-full touch-pan-x touch-pan-y overflow-auto  text-white selection:bg-purple-500">
        <SoundProvider>
          <MotionConfig reducedMotion="user">
            {/* SPLASH SCREEN */}
            <AnimatePresence>
              {!hasSeenAnimation && !isPwa && (
                <motion.div
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: 'spring',
                    bounce: 0,
                    stiffness: 120,
                    damping: 15
                  }}
                  className="fixed inset-0 z-40 grid place-items-center overflow-hidden bg-purple-800 standalone:hidden"
                  style={{ backgroundImage: `url(${pattern})` }}
                >
                  <div className="scale-[2]">
                    <Logo className="!inset-full -translate-x-1/2 -translate-y-1/2" />
                  </div>
                  <div className="circular-gradient absolute inset-0" />
                </motion.div>
              )}
            </AnimatePresence>

            {/* LOGO */}
            <Logo asLink={!isDailyPath} centered={!isDailyPath} />

            {hasSeenAnimation && children}
            <ScrollRestoration />
            <Scripts />
          </MotionConfig>
        </SoundProvider>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const outlet = useOutlet();
  const location = useLocation();

  // Disable exit animations for redirects
  const isRedirect = location.state?.isRedirect;

  return (
    <WebPushProvider>
      <div className="relative flex min-h-fit grow">
        {/* SOUND TOGGLE */}
        <div className="absolute right-4 z-30 top-safe-offset-4 md:right-10 md:top-safe-offset-10">
          <SoundToggle />
        </div>

        <AnimatePresence mode="wait" initial>
          <motion.main
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={isRedirect ? undefined : { opacity: 0 }}
            transition={{
              type: 'spring',
              bounce: 0,
              stiffness: 120,
              damping: 15
            }}
            className="relative min-h-fit grow overflow-hidden"
          >
            {outlet}
          </motion.main>
        </AnimatePresence>
        {/* BACKGROUND */}
        <div
          className="absolute -left-7 -top-7 bottom-0 right-0 -z-10"
          style={{ backgroundImage: `url(${pattern})` }}
        >
          <div className="circular-gradient absolute inset-0 rounded-t-2xl" />
        </div>
      </div>
    </WebPushProvider>
  );
}
